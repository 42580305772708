
import styles from './Reboot.module.scss'
import Transition from '../../../../lib/framerMotion/Transiton'
import setBodyColor from '../../../../utils/setBodyColor'
import { reboot } from '../../../../data/projects'
import ProjectBanner from '../../../../components/projectBanner/ProjectBanner'
import ProjectContent from '../../../../components/projectContent/ProjectContent'
import ProjectText from '../../../../components/projectText/ProjectText'
import collaboration from '../../../../assets/images/reboot-communication.png'
import ScrollNavigation from '../../../../components/scrollNavigation/ScrollNavigation'

export default function Reboot() {
  setBodyColor({color: '#1c1c1e'})
  return (
    <>
    <Transition>
        <ProjectBanner 
          data={reboot}/>
          <ProjectContent 
            heading="Authentication and OAuth"
          >
            <div className={styles.projectText}>
              <p>
                My responsibility for this project involved implementing user authentication and building the user interface. After completing the UI components, I proceeded to work on the authentication aspect. However, I encountered an issue when attempting to refresh the page on the profile page. An error message consistently appeared, stating that the currently logged-in user was not defined.
              </p>
              <p>
                I wansn't sure about the source of the error. After investing time into researching the problem, I came to realize that despite having integrated global state management to check the user's login status, the Firebase request was being initiated prior to the component's rendering process.
              </p>
              <p>
                And since I learned what is causing the error, the solution was way simpler than I thought. just check if a user is logged-in or not if not, else display a loading indicator! by this way, the loading spinner blinks in a very short time, but I was able to ensure that the error doesn't occur anymore.
              </p>
            </div>
          </ProjectContent>
          <ProjectContent
           heading="Collaboration and Effective Communication"
          >
            <div className={styles.imageContainer}>
              <img 
                  className={styles.image}
                  src={collaboration}
                  alt="communication methods" 
              />
            </div>
            <div className={styles.text}> 
              <p>
                "Reboot" is a reimagined version of a previous project. In the original iteration, we collaborated approximately two terms ago (around 6 months), assuming the same roles as we are now. Back then, despite my limited grasp of web development concepts, working on Reboot brought me a significant sense of accomplishment in having contributed to the creation of an entire website alongside the team.
              </p>
              <br />
              <p>
                Since my understanding and skills have improved in 6 months, I wanted to take this project with a professional manner. So in order to do that, I put more efforts on gathering design requirements if I wasn't sure on something I left comments in the working Figma file and directly asked them, also while developing the app, I made sure the requirements, trying to make what designers exactly wanted.
              </p>
              <br />
              <p>
                Not only communicating with designers, but also I tried to communicate well with the developer and keep on track our work progress by using GitHub's project board and issue tracking. 
              </p>
            </div>
          </ProjectContent>
          <ProjectContent
           heading="Takeaways"
          >
            <ProjectText>
                <p>
                  One of the most important skills for a developer at their entry level is indeed communication skill, and I was able to learn the significance of this firsthand throughout the course of this project. From discussing design intricacies with the team to seeking guidance when faced with challenges, I realized that clear communication fosters smoother collaboration and helps prevent misunderstandings. These experiences have underlined the value of strong communication in ensuring a project's success.
                </p>
            </ProjectText>
          </ProjectContent>
          <ScrollNavigation 
            navigateTo="tlsi-text-summarizer"
            title="TL;si - Too Long Summarize It"
            navigatePrev="reboot-fitness"
          /> 
      </Transition>
    </>
  )
}
