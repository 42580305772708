import { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

// import Navbar from '../navigation/Navbar'
import styles from './Header.module.scss'

function Header() {
  const navRef: any = useRef()

  let prevScrollpos = window.scrollY;

  const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (prevScrollpos > currentScrollPos - 1) {
          if(window.innerWidth < 1024 ) {
              navRef.current.style.top = "0";
          } else{
            navRef.current.style.top = "0";
          }
      } else {
          if(window.innerWidth < 1024) {
            navRef.current.style.top = "-6rem";
          } else {
            navRef.current.style.top = "-6rem";
          }
        
      }
      prevScrollpos = currentScrollPos;
  }

  useEffect(() => {
    // Add the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  return ( 
    <header 
      ref={navRef} 
      className={styles.container}
    > 
      <nav 
        className={styles.nav}
      >
          <ul className={styles.nav__navBar}>
            <li className={styles.nav__logo}>
              <Link to="/">
                <svg id="Layer_1" className={styles.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 109"><defs></defs><path d="M21,109h-4C7.61,109,0,101.39,0,92V17C0,7.61,7.61,0,17,0h4c29.27,0,53,23.73,53,53v3c0,29.27-23.73,53-53,53Z"/></svg>
              </Link>
            </li> 
            <li>            
              <Link className={`${styles.nav__email} active`} to='mailto:hello@dajeongpark.com'>Contact me!</Link>
            </li>
            <ul className={styles.nav__link}>
              <li>
                <Link to="/about" className='active'>ABOUT</Link>
              </li>
              <li>
                <Link to="/work" className='active'>WORK</Link>
              </li>
            </ul>
          </ul>
      </nav>
    </header>
  )
}

export default Header
