import styles from './Heading.module.scss'

export default function Heading({heading}) {
  return (
    <div className={styles.heading}>
      <h3 className='h2'> 
        {heading}
      </h3>
    </div>
  )
}
