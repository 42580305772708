import styles from './Definition.module.scss'
import { definition } from '../../../../data/projects';
import { definitionCode } from '../../../../data/code';
import setBodyColor from '../../../../utils/setBodyColor'
import Transition from '../../../../lib/framerMotion/Transiton';
import ScrollNavigation from '../../../../components/scrollNavigation/ScrollNavigation'
import Code from '../../../../lib/prism/Code';
import ProjectBanner from '../../../../components/projectBanner/ProjectBanner';
import ProjectContent from '../../../../components/projectContent/ProjectContent';
import ProjectText from '../../../../components/projectText/ProjectText';
import { 
  contextCode, 
  mailChimpCode,
  nodeMailerCode } 
from '../../../../data/code';


export default function Definition() {
    setBodyColor({color: '#1c1c1e'})

    return (
      <>
        <Transition>
          <ProjectBanner 
            data={definition}/>
            <ProjectContent 
              heading="Local Storage API"
            >
              <Code 
                language="javascript"
                code={ definitionCode }
              />
              <ProjectText>
                <p>
                  To accomplish seamless vocabulary management, this project leverages the Local Storage API. All wordbook entries are stored locally on the user's browser, ensuring that users' data remains intact even when they close the web application or navigate away from the page.
                </p>
              </ProjectText>
            </ProjectContent>
            <ProjectContent
              heading='TypeScript for Type Safety'  
            >
              <ProjectText>
                <p>
                  To ensure code reliability and maintainability, this project was developed entirely using TypeScript. TypeScript is a statically-typed superset of JavaScript, which means it adds type annotations to standard JavaScript code. By adopting TypeScript, I gained significant advantages in terms of type safety, enhanced code comprehension, and early detection of potential errors during development.
                </p>
              </ProjectText>
            </ProjectContent>
            <ProjectContent
              heading='Takeaways'  
            >
              <ProjectText>
                <p>
                  Through this project, I have gained practical experience in working with the Local Storage API for client-side data storage. Understanding how to use Local Storage effectively opens up possibilities for building lightweight and efficient web applications that provide offline support and enhance user experience.
                </p>
                <p>
                  And also by using TypeScript for the entire project, I have learned the importance of static type checking, ensuring more reliable and maintainable code.
                </p>
              </ProjectText>
            </ProjectContent>
        
            <ScrollNavigation 
              navigateTo="photo-gallery"
              title="Photo Gallery"
              navigatePrev="tlsi-text-summarizer"
            /> 
        </Transition>
      </>
    )
}


