
import { Outlet } from 'react-router-dom'

import styles from './Work.module.scss'
import Card from '../../components/card/Card'
import Footer from '../../layouts/footer/Footer'
import Transiton from '../../lib/framerMotion/Transiton'
import setBodyColor from '../../utils/setBodyColor'

function Work() {

  setBodyColor({color: '#1c1c1e'})

  return (
    <>
      <Transiton>
        <section className={`${styles.container} wrapper`}>
          <Card
            heading='All Projects'
          />
        </section>
        <Footer />
        <Outlet />
      </Transiton>
    </>
  )
}

export default Work
