export const projects = [
     {
        name: "Articles of the Week from NY Times",
        desc: "a web application with a newsletter subscription feature to view the most popular articles for the week.",
        stacks: ["React", "TypeScript", "Node.js", "Express.js", "Bootstrap"],
        src: require('../assets/images/articles-of-the-week-banner.png'),
        placeholderSrc: require('../assets/images/articles-of-the-week-banner-blurred.webp'),
        url: "/work/articles-of-the-week-from-ny-times"
    },
    {
        name: "Reboot Fitness",
        desc: "Reboot is a gym fitness website with authentication and booking systems. Reboot is a group project with I worked with two of UX/UI designers and a developer from school.",
        stacks: ["React", "SASS", "Firebase"],
        src: require('../assets/images/reboot-banner.png'),
        placeholderSrc: require('../assets/images/reboot-banner-blurred.webp'),
        url: "/work/reboot-fitness"
    },
    {
        name: "TL;si - Too Long Summarize It",
        desc: "a web application that uses OpenAI's text summarization capabilities to condense lengthy texts into concise summaries",
        stacks: ["React", "Node.js", "Express.js", "Tailwind", "OpenAI"],
        src: require('../assets/images/too-long-summarize-it-banner.png'),
        placeholderSrc: require('../assets/images/too-long-summarize-it-banner-blurred.webp'),
        url: "/work/tlsi-text-summarizer"
    },
    {
        name: "Definition - Vocabulary Wordbook",
        desc: "a web application to search definitions and save them for quick reference",
        stacks: ["HTML", "TypeScript", "SASS"],
        src: require('../assets/images/definition-banner.png'),
        placeholderSrc: require('../assets/images/definition-banner-blurred.webp'),
        url: "/work/definition"
    },
    {
        name: "Photo Gallery",
        desc: "a digital photo gallery where you can explore with mouse interaction",
        stacks: ["React", "JavaScript", "CSS Grid Layout"],
        src: require('../assets/images/photo-gallery-banner.png'),
        placeholderSrc: require('../assets/images/photo-gallery-banner-blurred.webp'),
        url: "/work/photo-gallery"
    }
]

export const articlesOfTheWeek = [
    {
        src: require('../assets/images/articles-of-the-week-banner.png'),
        placeholderSrc: require('../assets/images/articles-of-the-week-banner-blurred.webp'),
        src_m: require('../assets/images/articles-of-the-week-banner--mobile.png'),
        placeholderSrc_m: require('../assets/images/articles-of-the-week-banner--mobile-blurred.webp'),
        name: "Articles of the Week from NY Times",
        timeline: "May - June 2023",
        stacks: ["React", "TypeScript", "Node.js", "Express.js", "Bootstrap"],
        role: ["Front ·", " Backend developer", " & designer"],
        site: "http://articles-of-the-week.dajeongpark.com/",
        github: "https://github.com/dajeongpaak/articles-of-the-week-from-nytimes",
        description: ["Articles of the Week from NY Times is a web application that provides users with the ability to view the most viewed articles from the New York Times for the last 7 days. With a simple and user-friendly interface, users can easily access and stay informed about the latest news and trending topics.",
        "In addition to article viewing, the application offers a newsletter subscription feature, allowing users to stay up-to-date with the latest articles and receive weekly updates directly in their email inbox. By subscribing to the newsletter, users can ensure they never miss out on important news and stay connected with the NY Times."]
    }
]

export const tlsi = [
    {
        src: require('../assets/images/too-long-summarize-it-banner.png'),
        placeholderSrc: require('../assets/images/too-long-summarize-it-banner-blurred.webp'),
        src_m: require('../assets/images/too-long-summarize-it-banner--mobile.png'),
        placeholderSrc_m: require('../assets/images/too-long-summarize-it-banner--mobile-blurred.webp'),
        name: "TL;si - Too Long Summarize It",
        timeline: "June 2023",
        stacks: ["React", "Node.js", "Express.js", "Tailwind", "OpenAI"],
        role: ["Front ·", " Backend developer", " & designer"],
        site: "https://too-long-summarize-it-cd67d696e9ae.herokuapp.com",
        github: "https://github.com/dajeongpaak/text-summarizer",
        description: ["TL;si is a web application that uses OpenAI's text summarization capabilities to condense lengthy texts into concise summaries. It allows users to input text and quickly generate a summarized version of that text. ",
        "Along with the summarization feature, TL;si also provides convenient options to copy the summarized text or download it as a text file. With TL;si, users can save time and effort by efficiently extracting the key information from long texts."]
    }
]

export const gallery = [
    {
        src: require('../assets/images/photo-gallery-banner.png'),
        placeholderSrc: require('../assets/images/photo-gallery-banner-blurred.webp'),
        src_m: require('../assets/images/photo-gallery-banner--mobile.png'),
        placeholderSrc_m: require('../assets/images/photo-gallery-banner--mobile-blurred.webp'),
        name: "Photo Gallery",
        timeline: "June 2023",
        stacks: ["React", "JavaScript", "CSS Grid Layout"],
        role: ["Frontend developer", " & designer"],
        site: "https://dajeongpark.com/#/gallery",
        github: "https://github.com/dajeongpaak/text-summarizer",
        description: ["The Interactive Digital Photo Gallery is a distinctive project that seamlessly blends my personal touch, expertise in frontend development, and my passion and love for photography.",
        "This project introduces an interactive feature that allows users to navigate through the gallery using the position of their mouse (desktop only, sadly)."]
    }
]

export const definition = [
    {
        src: require('../assets/images/definition-banner.png'),
        placeholderSrc: require('../assets/images/definition-banner-blurred.webp'),
        src_m: require('../assets/images/definition-banner--mobile.png'),
        placeholderSrc_m: require('../assets/images/definition-banner--mobile-blurred.webp'),
        name: "Definition - Vocabulary Wordbook",
        timeline: "July 2023",
        stacks: ["HTML", "TypeScript", "SASS"],
        role: ["Frontend developer", " & designer"],
        site: "https://definition.dajeongpark.com",
        github: "https://github.com/dajeongpaak/text-summarizer",
        description: ["Definition is an online learning tool that allows users to search for word definitions and store them as quick references for later. With a user-friendly interface, the application empowers users to effortlessly expand their vocabulary."]
    }
]

export const reboot = [
    {
        src: require('../assets/images/reboot-banner.png'),
        placeholderSrc: require('../assets/images/reboot-banner-blurred.webp'),
        src_m: require('../assets/images/reboot-banner-mobile.png'),
        placeholderSrc_m: require('../assets/images/reboot-banner-mobile.png'),
        name: "Reboot Fitness",
        timeline: "July-August 2023",
        stacks: ["React", "SASS", "Firebase"],
        role: ["Frontend developer"],
        collaborators: [
            {
                member: "Haerin Bok",
                link: "https://haerinbok.com/",
                role: "UI/UX Designer"
            },{
                member: "Mattias Bellan",
                link: "https://mattiasbellan.ca/public/",
                role: "Frontend Developer"
            },{
                member: "Megan Poon",
                link: "https://meganpoon.com/",
                role: "UI/UX Designer"
            }
        ],
        site: "https://reboot.dajeongpark.com/",
        github: "https://github.com/MattiasSB/RebootFitness",
        description: ["Reboot is a fitness website with authentication and booking systems. This is a group project that I worked with two of UX/UI designers and a developer from school. My role was a frontend developer that implemented login authentication with firebase and built pages for UI."]
    }
]

