
import { useRef, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import styles from './Card.module.scss'
import LazyImg from '../../lib/lazyLoading/LazyImg'
import {projects} from '../../data/projects'

interface Item {
    name: string;
    desc: string;
    stacks: string[];
    src: typeof import("*.png");
    placeholderSrc: typeof import("*.webp");
    url: string;
}

export default function Card({heading}: any) {

    const location = useLocation()
    const limitedItems = projects.slice(0, 2)
    const items: Item[] = projects as Item[]
    const cardRef: any = useRef()

    useLayoutEffect(() => { 

        const cardSelector = gsap.utils.selector(cardRef)

        let ctx = gsap.context(() => {
  
        const tl = gsap.timeline()

            cardSelector("#js-card").forEach((card) => {

                tl.to(card, {
                    filter:'blur(0px)'
                })

                ScrollTrigger.create({
                    animation: tl,
                    trigger: cardRef.current,
                    start: "top 130%",
                    end: "bottom bottom",
                    scrub: true,
                })
                })
            
            })
          return () => {

            ctx.revert()
  
          }
        }, [])
   
    return (
        <>
            <div 
                ref={cardRef}
                className={`${styles.projects}`}
            >
                <div 
                className={styles.heading}
                >
                    <h3 className="h1">
                    {heading}
                    </h3>
                </div>
                {(location.pathname === '/' ? limitedItems : items).map((item, index) => (
                    <Link  
                        id='js-card'
                        to={item.url} 
                        key={index} 
                        className={styles.card}
                    >
                        <div 
                            id='js-preview'
                            className={styles.preview}
                        >
                            <div className={styles.content}>
                                <div className={styles.title}>
                                    <h4 className='h2 active'>{item.name}</h4>
                                    <p>Web Development</p>
                                </div>
                                <div className={styles.previewImg}>
                                    <LazyImg 
                                        src={typeof item.src === 'string' ? item.src : item.src.default} 
                                        placeholderSrc={typeof item.placeholderSrc === 'string' ? item.placeholderSrc : item.placeholderSrc.default}
                                        alt={item.name}
                                    />
                                </div>
                                <div className={styles.overview}>
                                    <div className={styles.technology}>
                                        <h5>Technology used</h5>
                                        <div className={styles.discipline}>
                                            {item.stacks.map((stack, stackIndex) => (
                                                <p key={stackIndex}>{stack}</p>
                                                ))}
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Overview</h5>
                                        <p className={styles.description}>{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))} 
            </div> 
        </>
      )
}
