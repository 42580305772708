import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import styles from './About.module.scss'
import Transition from '../../lib/framerMotion/Transiton'
import setBodyColor from '../../utils/setBodyColor'
import { skills, values } from '../../data/about'
import myImage from '../../assets/images/DJ.jpg'
import placeholderImg from '../../assets/images/DJ-blurred.jpg'
import camera from '../../assets/images/film-camera.png'
import placeholderImgCamera from '../../assets/images/film-camera_blurred.png' 
import LazyImg from '../../lib/lazyLoading/LazyImg'
import Heading from '../../components/heading/Heading'

export default function About() {

    const location = useLocation()
    const email = 'hello@dajeongpark.com' 

    useEffect(() => {
        const setBodyOverflow = () => {
            if(location.pathname === '/about' && window.innerWidth >= 1040) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'visible'
            }
        }
    
        setBodyOverflow()

        const handleResize = () => {
            setBodyOverflow();
          };

           window.addEventListener('resize', handleResize);

    return () => {
        document.body.style.overflow = 'visible';
      window.removeEventListener('resize', handleResize);
    };
  }, [location.pathname]);

    setBodyColor({color: '#1c1c1e'})

  return (
    <>
    <Transition>
      <section className={styles.section}>
        <div className={styles.grid}>
            <aside>
                <h1>DJ Park –– Front End Developer</h1>
                <h2>
                    <span>
                        Based in
                    </span><br />
                    Vancouver
                </h2>
                <Link 
                    to={`mailto:${email}`} 
                    className={`active`}>
                    {email}
                </Link>
            </aside>
            <div className={styles.content}>
                <Heading 
                    heading="More About Me"
                />
                <div className={styles.myImg}>
                    <LazyImg 
                        src={myImage}
                        placeholderSrc={placeholderImg}
                        alt="Dajeong Park Profile Image"
                    />
                </div>
                <div className={styles.moreAboutMe}>
                    <h4 className='h5'>
                        Hi, I’m Dajeong!{window.innerWidth <= 575 ? <br /> : ' '}
                        You can call me DJ
                    </h4>
                    <p>
                    I am a front-end developer with a strong passion in JavaScript, React and Node.js. While my primary focus remains on frontend development, my growing interest in backend development with Node.js has led me to set a long-term goal of becoming a full-stack developer within the next two years. 
                    By expanding my skills to encompass both frontend and backend development, I aim to contribute to the creation of practical and impactful applications from end to end.
                    </p>
                    <br />
                    <p>
                    My journey towards this goal began with assisting and supporting my classmates with coding during my time in school. This experience made me realize how fulfilling it is to help others, and it inspired me to channel that passion into my work.
                    To reach my goals, I actively pursue learning opportunities outside the classroom. I believe continuous learning and hands-on experience are vital for becoming a proficient full-stack developer.
                    </p>
                </div>
                <Heading 
                    heading="Skills"
                />
                <ul className={styles.skillsList}>
                    {skills.map((item, index) => (
                        <li key={index}>
                            <h4>{item.title}</h4>
                            {item.stacks.map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </li>
                    ))}
                </ul>
                <Heading 
                    heading="Values"
                />
                <ul className={styles.valueList}>
                    {values.map((item, index) => (
                        <li key={index}>
                            <h4>{item.title}</h4>
                            <p>{item.desc}</p>
                        </li>
                    ))}
                </ul>
                <Heading 
                    heading="In My Free Time"
                />
                <div className={styles.hobbies}>
                    <div className={styles.hobbyList}>
                        <h4>You might catch me</h4>
                        <p>
                        Running, Climbing, Snowboarding,
                        Playing boardgames with my roommate after Cooking and Enjoying new dishes,
                        On the beach in summer days
                        </p>
                        <br />
                        <p>
                        And Film Photography, 
                        <br />if you like to view stuff I took
                        </p>
                        <div className={styles.click}>
                            <p>click the camera</p>
                            <svg viewBox="0 0 20 20">
                                <path d="M18.6071 20 20 18.607 3.33817 2h10.67094V0H0v14.093l2.0013.03V3.366z"/>
                            </svg>
                        </div>
                    </div>
                    <Link to={"/gallery"} className={styles.camera}>
                    <LazyImg 
                        src={camera}
                        placeholderSrc={placeholderImgCamera}
                        alt="film camera"
                    />
                    </Link>
                </div>
            </div>
        </div>
      </section>
      </Transition>
    </>
  )
}
