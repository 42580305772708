import { useState ,useEffect, useRef } from 'react'

import { Composite, Runner, Mouse, MouseConstraint } from "matter-js"
import Matter from 'matter-js';

import styles from './Hero.module.scss'
import setBodyColor from '../../../utils/setBodyColor';
import front from '../../../assets/images/front.png'
import end from '../../../assets/images/end.png'
import developer from '../../../assets/images/developer.png'
import based from '../../../assets/images/based.png'
import vancouver from '../../../assets/images/vancouver.png'


function Hero() {
    const heroRef = useRef();
    // const canvas = useRef();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight, 
    })
    //const [ground, setGround] = useState(null);
    setBodyColor({color: '#1c1c1e'})


    useEffect(() => {

        let Engine = Matter.Engine;
        let Render = Matter.Render;
        let Body = Matter.Body;
        let Bodies = Matter.Bodies;
        let Events = Matter.Events;
        let Common = Matter.Common;

        const heroWidth = heroRef.current.clientWidth
        const heroHeight = heroRef.current.clientHeight
  
        let actualPixel = 360
        let expectedRatio = 0.18
        let scaleFactor = 
            heroWidth > heroHeight ? 
            (heroWidth * expectedRatio) / actualPixel : 
            heroHeight * (expectedRatio * 0.87) / actualPixel

        // create an engine
        var engine = Engine.create(),
            world = engine.world;

        // set physics container width and height
      
        // create a renderer
        var render = Render.create({
            element: heroRef.current,
            engine: engine,
            options: {
                width: heroWidth,
                height:  heroHeight,
                wireframes: false,
                background: 'transparent',
                pixelRatio: 1,
            }
        });

    
       // create physics elements
       var FRONT = Bodies.rectangle(heroWidth * 1.69/10, heroHeight - 30, 670*scaleFactor , 180*scaleFactor, {
        density: 0.04,
        friction: 0.1,
        frictionAir: 0.005,
        restitution: 0.3,
        render: { 
        sprite: { 
            texture: front, 
            xScale: scaleFactor, 
            yScale: scaleFactor  }}
        });

    var END = Bodies.rectangle(heroWidth * 4.3/10, heroHeight -70, 340 * scaleFactor, 180*scaleFactor,  {
        slop: 0.5,
        frictionStatic: Infinity,
        density: 0.04,
        friction: 0.1,
        frictionAir: 0.005,
        restitution: 0.3,
        render: { 
        sprite: { 
            texture: end, 
            xScale: scaleFactor, 
            yScale: scaleFactor }}
        });
    
    var DEVELOPER = Bodies.rectangle(heroWidth *2.65/10, heroHeight - 3, 1000 * scaleFactor, 180*scaleFactor,  {
        density: 0.04,
        friction: 1,
        frictionAir: 0.000001,
        restitution: 0.7,
        render: { 
        sprite: { 
            texture: developer, 
            xScale: scaleFactor, 
            yScale: scaleFactor  }}
        });

         
    var BASED = Bodies.rectangle(heroWidth * 9/10, heroHeight - 30 , 340 * scaleFactor, 100 * scaleFactor,  {
        density: 0.04,
        friction: 1,
        frictionAir: 0.000001,
        restitution: 0.7,
        render: { 
        sprite: { 
            texture: based, 
            xScale: scaleFactor, 
            yScale: scaleFactor  }}
        });

         
    var VANCOUVER = Bodies.rectangle(heroWidth * 8.2/10, heroHeight - 10, 660 * scaleFactor, 120*scaleFactor,  {
        density: 0.04,
        friction: 1,
        frictionAir: 0.000001,
        restitution: 0.7,
        render: { 
        sprite: { 
            texture: vancouver, 
            xScale: scaleFactor, 
            yScale: scaleFactor  }}
        });

    var groundBody = Bodies.rectangle(heroWidth / 2, heroHeight, 82854, 1, { 
        isStatic: true,
        render: {
            fillStyle: 'rgba(0,0,0,0)'
        }
    });

    var roof = Bodies.rectangle(heroWidth / 2, 0, 82854, 1, { 
        isStatic: true,
        render: {
            fillStyle: 'rgba(0,0,0,0)'
        }
    });

    var leftwall = Bodies.rectangle( -30, heroHeight / 2, 30 , heroHeight * 5, { 
        isStatic: true,
        render: {
            fillStyle: 'rgba(0,0,0,0)'
        }
    });

    var rightwall = Bodies.rectangle( heroWidth, heroHeight / 2, 1, heroHeight * 5, {
        isStatic: true,
        render: {
            fillStyle: 'rgba(0,0,0,0)'
        }
    });


    // add all of the bodies to the world
    Composite.add(world, [FRONT, END, DEVELOPER, BASED, VANCOUVER, groundBody, roof, leftwall, rightwall,]);
    var explosion = function(engine, delta) {
        var timeScale = (1000 / 60) / delta;
        var bodies = Composite.allBodies(engine.world);

        for (var i = 0; i < bodies.length; i++) {
            var body = bodies[i];

            if (!body.isStatic && body.position.y >= 500) {
                // scale force for mass and time applied
                var forceMagnitude = (0.05 * body.mass) * timeScale;

                // apply the force over a single update
                Body.applyForce(body, body.position, {
                    x: (forceMagnitude + Common.random() * forceMagnitude) * Common.choose([1, -1]), 
                    y: -forceMagnitude + Common.random() * -forceMagnitude
                });
            }
        }
    };

    var timeScaleTarget = 1,
    lastTime = Common.now();

    Events.on(engine, 'afterUpdate', function(event) {
        var timeScale = (event.delta || (1000 / 100)) / 1000;

        engine.timing.timeScale += (timeScaleTarget - engine.timing.timeScale) * 10 * timeScale;

        if (Common.now() - lastTime >= 4500) {

            // flip the timescale
            if (timeScaleTarget < 1) {
                timeScaleTarget = 1;
            } else {
                timeScaleTarget = 0.1;

                setTimeout(() => {
                    timeScaleTarget = 1;
                }, 1500)
            }

            explosion(engine, event.delta);

            lastTime = Common.now();
        }
    });

    setTimeout(() => {
        Events.off(engine, 'afterUpdate')
    }, 8000)

        var canvas = render.canvas;
        const cwidth = canvas.width;
        const cheight = canvas.height;

        let mouse = Mouse.create(canvas);
        let mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });

        Composite.add(world, mouseConstraint);

        mouseConstraint.mouse.element.removeEventListener(
            'mousewheel',
            mouseConstraint.mouse.mousewheel
        );

        mouseConstraint.mouse.element.removeEventListener(
            'DOMMouseScroll',
            mouseConstraint.mouse.mousewheel
        );

        mouseConstraint.mouse.element.removeEventListener(
            'touchstart', 
            mouseConstraint.mouse.mousedown
        );

        mouseConstraint.mouse.element.removeEventListener(
            'touchmove',
            mouseConstraint.mouse.mousemove
        );

        mouseConstraint.mouse.element.removeEventListener(
            'touchend', 
            mouseConstraint.mouse.mouseup
        );

        mouseConstraint.mouse.element.addEventListener(
            'touchstart', 
            mouseConstraint.mouse.mousedown, { passive: true });

        mouseConstraint.mouse.element.addEventListener('touchmove', (e) => {
        if (mouseConstraint.body) {
        mouseConstraint.mouse.mousemove(e);
        }
        });

        mouseConstraint.mouse.element.addEventListener('touchend', (e) => {
        if (mouseConstraint.body) {
        mouseConstraint.mouse.mouseup(e);
        }
        });


        // run the renderer
        Render.run(render);

        // create runner
        var runner = Runner.create();

        // run the engine
        Runner.run(runner, engine);

        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
            const widthHero = heroRef.current.clientWidth
            const heightHero = heroRef.current.clientHeight

            const canvashero = document.querySelector("canvas");

            canvashero.style.width = `${widthHero}px`
            canvashero.style.height = `${heightHero}px`

            render.canvas.width = widthHero;
            render.canvas.height = heightHero;

            Matter.Body.setPosition(groundBody, 
                Matter.Vector.create(
                    widthHero / 2,
                    heightHero 
            ))

            Matter.Body.setPosition(rightwall,
                Matter.Vector.create(
                    widthHero ,
                    heightHero / 2
                    )
            );
        
        }

        window.addEventListener('resize',handleResize )

        // prevent from rendering this component twice by strict mode
        return () => {

            
            // Stop the renderer and runner
            Render.stop(render);
            Runner.stop(runner);

            // Clear the engine and world
            Composite.clear(world);
            Engine.clear(engine);
            render.canvas.remove()
            render.canvas = null
            render.context = null
            window.removeEventListener('resize',handleResize )
            // Remove the event listener when the component unmounts
            
        }
    }, [window.innerWidth])

  return (
    <>
        <section
            ref={heroRef}
            className={styles.hero}
        >
        </section>
        <section
            className={styles.greeting}    
        >
        <h1>Hey there, I'm DJ</h1>
        <h2>
        I develop and design engaging user experiences 
        that bring emotional connection
        <br /><br />
        through Creativity, Lifelong Learning, <br />
        Technology Skills & Passion
        </h2>
        </section>
    </>
  )
}

export default Hero
