import { useRef } from "react";
import { Link , useNavigate} from "react-router-dom";

import styles from './FeaturedWork.module.scss'
import Card from '../../../components/card/Card'

function FeaturedWork() {
  const featuredWorkRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate()
//   useEffect(() => {

//     const handleScroll = () => {
//       const rect = featuredWorkRef.current?.getBoundingClientRect()
//       const { top }: RectValues = rect || { top: 0 }

//       document.body.style.backgroundColor = top < 0 ? "#1c1c1e" : "#060606"
//   }

//   window.addEventListener('scroll', handleScroll)

//   return () => {
//     window.removeEventListener('scroll', handleScroll)
//   } 

// },[])
  return (
    <section 
      ref={featuredWorkRef} 
      className={`${styles.container} wrapper`}
    >
      <Card 
        heading='Featured Projects'
      />
      <div className={`${styles.btnContainer}`}> 
        <p 
          onClick={() => navigate('/work')}
          className={`active ${styles.btn}`}
        >View All Projects
      </p>
      </div>

    </section>
  )
}

export default FeaturedWork
