
import { Link, useLocation } from 'react-router-dom'
import styles from './Footer.module.scss'
import BackToTop from '../../components/backToTop/BackToTop'
import Arrow from '../../assets/icons/Arrow'

function Footer(): JSX.Element {
  const email = 'hello@dajeongpark.com' 
  const location = useLocation()

  return (
    <footer 
      className={`wrapper ${location.pathname === '/' ? styles.homePadding : null}`}
    >
      <div className={styles.container}>
       <div className={styles.getInTouch}>
          <h4 className='h2'>Get In Touch!</h4>
          <BackToTop />
       </div>
      <Link to={`mailto:${email}`} className={`${styles.contact} active`}>{email}
      </Link>
      <div className={styles.socialMedia}>
          <p className='description'>&copy;{new Date().getFullYear()}</p>
          <div className={styles.socialMediaLink}>
            <Link 
            to="https://www.linkedin.com/in/dajeong-park-4956bb255/" target='_blank' 
            rel="noreferrer"
            >
              <p className='active'
              >LinkedIn</p>
            </Link>
            <Link 
            to="https://github.com/dajeongpaak" 
            target='_blank' 
            rel="noreferrer"
            className='active' 
            >
              <p
               className='active'
               >GitHub</p>
            </Link>
          </div>
      </div>
      </div>
    </footer>
  )
}

export default Footer