
import styles from './BackToTop.module.scss'

export default function BackToTop() {

const handleScroll = () => {
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
    });
    }  
    
  return (
    <div className={styles.backToTop} onClick={handleScroll}>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.76 59.67"><path d="M59.76,2V43c0,1.1-.9,2-2,2s-2-.9-2-2V6.81L3.5,59.07c-.8,.8-2.1,.8-2.9,0s-.8-2.1,0-2.9L52.72,4.05H16.26c-1.1,0-2-.9-2-2h0C14.26,.94,15.16,.04,16.26,.04H57.26s.04,0,.06,0c.14-.03,.29-.05,.44-.05,1.1,0,2,.9,2,2Z"/>
        </svg>
    <p>Back To Top</p>
  </div>
  )
}
