export const skills = [
    {
        title: "Frontend",
        stacks: ["HTML", "CSS", "SASS", "JavaScript", "TypeScript",  "React", "React Native", "JQuery", "Tailwind", "Bootstrap"]
    },
    {
        title: "Backend",
        stacks: ["Node.js", "Express.js", "PHP", "MySQL", "Firebase"]
    },
    {
        title: "Misc Tools",
        stacks: ["Git", "GitHub", "WordPress", "Figma", "Trello", "Jira", "Adobe Suite"]
    }
]

export const values = [
    {
        title: "Optimism",
        desc: "When I encounter challenges in general I see them as opportunities for growth and learning. It helps me maintain resilience and motivation."
    },
    {
        title: "Helpfulness",
        desc: "I have a genuine passion for helping others, when working in a team, a strong desire to be helpful is the key for me to build trust among team members and foster a positive work environment."
    },
    {
        title: "Curiosity",
        desc: "“What if” phases are always driving me to learn more and embrace challenges. It has been a good starting point to explore the possibility of implementing something new on projects or even making life decisions."
    }
]